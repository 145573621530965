<template>
  <div class="main-monitor">
    <div class="search-bar" id="searchInput">
      <input
        type="text"
        v-model="searchQuery"
        placeholder="搜索位置"
        id="tipinput"
        @keyup.enter="searchLocation"
      />
      <button @click="searchLocation">搜索</button>
      <button @click="clearSearch">取消</button>
    </div>
    <div class="monitor-map" ref="monitorMapRef"></div>
    <div class="add-prj" :class="adding ? 'blueContainer' : ''" @click="addPrj">新建<br />项目</div>
    <div class="addPrj-steps" v-show="showStep">
      <button class="close-btn" @click="closeSteps">×</button>
      <h3 class="addPrj-title">操作步骤：</h3>
      <ol class="addPrj-list">
        <li>在地图中找到项目位置单击鼠标左键</li>
        <li>然后自动打开新建项目属性对话框，设置项目信息</li>
      </ol>
    </div>
  </div>
</template>

<script setup lang="ts">
import AMapLoader from '@amap/amap-jsapi-loader'
import ApiKeys from '@/config/ApiKeys.json'
import { ref, onMounted, watch, createApp } from 'vue'
import { getPrjInfo } from '../service/PrjInfo'
import HomePrjInfo from '@/components/mapWindow/HomePrjInfo.vue'
import AddProjectInfo from '@/components/mapWindow/AddProjectInfo.vue'

const monitorMapRef = ref(null)
const prjList = ref<any>([])
const markerMap = new Map()

// 定义类型
type MaybeRef<T> = T | null

let map: MaybeRef<any> = null
let infoWindow: MaybeRef<any> = null
let AMapInstance: MaybeRef<any> = null
const adding = ref<boolean>(false)
const showStep = ref<boolean>(false)
let marker: MaybeRef<any> = null

let placeSearch: any = null
const searchQuery = ref('')

const autoOptions = {
  input: 'tipinput' // 绑定输入提示框的 DOM ID
}

const clearSearch = () => {
  if (placeSearch) {
    searchQuery.value = ''
    placeSearch.clear()
  }
}

// 搜索地点并在地图上展示
const searchLocation = () => {
  if (!searchQuery.value || !AMapInstance) {
    clearSearch()
    return
  }

  // 确保插件加载
  AMapInstance.plugin(['AMap.PlaceSearch', 'AMap.AutoComplete'], () => {
    if (!placeSearch) {
      placeSearch = new AMapInstance.PlaceSearch({
        city: '310000',
        map: map
      })
    } else {
      placeSearch.clear()
    }
    // 输入提示功能
    const auto = new AMapInstance.AutoComplete(autoOptions)
    auto.on('select', (e: any) => {
      placeSearch.setCity('310000')
      placeSearch.search(e.poi.name)
    })

    placeSearch.search(searchQuery.value, (status: string, result: any) => {
      if (status === 'complete' && result.info === 'OK') {
        const pois = result.poiList.pois
        if (pois.length > 0) {
          const firstPoi = pois[0]
          const position = [firstPoi.location.lng, firstPoi.location.lat]
          map.setCenter(position)
        }
      } else {
        console.log('无搜索结果')
      }
    })
  })
}
const deletePrj = (id: number) => {
  console.log(110101010)
  prjList.value = prjList.value.filter((item: any) => item.id !== id)
  console.log('执行了', markerMap.get(id))
  AMapInstance.remove(markerMap.get(id))
  markerMap.delete(id)

  infoWindow.close()
}

// 新增项目
const newPrj = (e: any) => {
  var position = e.lnglat
  if (marker) {
    map.remove(marker)
    // marker.remove()
  }
  const markerIcon = new AMapInstance.Icon({
    size: new AMapInstance.Size(45, 45),
    image: 'https://image.surveyexcel.com/1200w_900h_50q_dfc87a13-fe85-4173-8eb5-4df98f2028bb',
    imageSize: new AMapInstance.Size(45, 45)
  })
  marker = new AMapInstance.Marker({
    position: position,
    map: map,
    icon: markerIcon,
    offset: new AMapInstance.Pixel(-22, -42)
  })
  if (!infoWindow) {
    infoWindow = new AMapInstance.InfoWindow({
      isCustom: true,
      offset: new AMapInstance.Pixel(0, -50)
    })
  }

  const closeInfoWindow = () => {
    if (infoWindow) {
      infoWindow.close()
      map.remove(marker)
      // marker.remove()
    }
  }
  const addedPrj = (data: any) => {
    infoWindow.close()
    map.off('click', newPrj)
    prjList.value.push(data)
    adding.value = false

    marker.on('click', function () {
      if (!infoWindow) {
        infoWindow = new AMap.InfoWindow({
          isCustom: true,
          offset: new AMap.Pixel(0, -50)
        })
      }
      // 创建Vue组件实例
      const tempDiv = document.createElement('div')
      const app = createApp(HomePrjInfo, { prjInfo: data, deletePrj: deletePrj })
      // 挂载Vue应用到临时DOM
      app.mount(tempDiv)
      // 设置内容并打开窗体
      infoWindow.setContent(tempDiv as HTMLElement)
      infoWindow.open(map, marker.getPosition())
    })
  }

  // 创建Vue组件实例
  const tempDiv = document.createElement('div')
  const app = createApp(AddProjectInfo, {
    position: position,
    closeInfoWindow: closeInfoWindow,
    addedPrj: addedPrj
  })
  // 挂载Vue应用到临时DOM
  app.mount(tempDiv)
  // 设置内容并打开窗体
  infoWindow.setContent(tempDiv as HTMLElement)
  infoWindow.open(map, marker.getPosition())
}
const addPrj = () => {
  if (adding.value) {
    adding.value = false
    showStep.value = false
    map.off('click', newPrj)
  } else {
    map.on('click', newPrj)
    adding.value = true
    showStep.value = true
  }
}

const closeSteps = () => {
  showStep.value = false
}

const renderMap = () => {
  //@ts-ignore
  window._AMapSecurityConfig = {
    securityJsCode: ApiKeys.securityJsCode
  }
  AMapLoader.load({
    key: ApiKeys.apiKey,
    version: '2.0',
    plugins: ['AMap.PlaceSearch', 'AMap.Marker', 'AMap.ToolBar']
  }).then(async (AMap: any) => {
    AMapInstance = AMap
    map = new AMap.Map(monitorMapRef.value, {
      viewMode: '2D', // 设置为3D视图模式
      center: [121.47, 31.23],
      zoom: 12
      // mapStyle: "amap://styles/macaron"
    })
    prjList.value.forEach((item: any, index: number) => {
      const markerIcon = new AMap.Icon({
        size: new AMap.Size(45, 45),
        image: 'https://image.surveyexcel.com/1200w_900h_50q_dfc87a13-fe85-4173-8eb5-4df98f2028bb',
        imageSize: new AMap.Size(45, 45)
      })
      const statusMarker = new AMap.Marker({
        position: [item.lng, item.lat],
        map: map,
        icon: markerIcon,
        offset: new AMap.Pixel(-22, -42)
      })
      statusMarker.on('click', function () {
        if (!infoWindow) {
          infoWindow = new AMap.InfoWindow({
            isCustom: true,
            offset: new AMap.Pixel(0, -50)
          })
        }
        // 创建Vue组件实例
        const tempDiv = document.createElement('div')
        const app = createApp(HomePrjInfo, { prjInfo: item, deletePrj: deletePrj })
        // 挂载Vue应用到临时DOM
        app.mount(tempDiv)
        // 设置内容并打开窗体
        infoWindow.setContent(tempDiv as HTMLElement)
        infoWindow.open(map, statusMarker.getPosition())
      })
      // 监听地图点击事件，关闭信息窗体
      map.on('click', (e: any) => {
        if (infoWindow) {
          infoWindow.close()
        }
      })
      markerMap.set(item.id, statusMarker)
    })
  })
}

watch(prjList, async (newValue, oldValue) => {
  renderMap()
})

onMounted(async () => {
  prjList.value = await getPrjInfo()
  renderMap()
})
</script>

<style scoped lang="scss">
.main-monitor {
  width: 100vw;
  height: calc(100vh - 10px);

  .search-bar {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 999;
    display: flex;
    input {
      padding: 5px;
      width: 200px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
    button {
      padding: 5px 10px;
      margin-left: 10px;
      background-color: #508ef5;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }
  }

  .monitor-map {
    width: 100%;
    height: 100%;
  }
  .add-prj {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 90px;
    right: 90px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
    font-size: 14px;
    line-height: 16px;
    border: 1px solid #ccc;
    vertical-align: middle;
  }
  .addPrj-text {
    width: 300px;
    height: 100px;
    background-color: #fff;
    border-radius: 5px;
  }
  .addPrj-steps {
    position: absolute;
    top: 90px;
    right: 155px;
    background: #f7f7f7;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    .close-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      border: none;
      background: transparent;
      cursor: pointer;
      font-size: 24px;
      line-height: 1;
      color: #aaa;
      &:hover {
        color: #777;
      }
    }
    .addPrj-title {
      font-size: 18px;
      color: #333;
      margin-bottom: 10px;
    }
    .addPrj-list {
      list-style-type: none;
      counter-reset: step-counter;
      padding-left: 0;
      li {
        counter-increment: step-counter;
        font-size: 16px;
        line-height: 1.5;
        position: relative;
        padding-left: 30px;
        margin-bottom: 5px;
        &::before {
          content: counter(step-counter);
          position: absolute;
          left: 0;
          top: 0;
          background-color: #508ef5;
          color: white;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          text-align: center;
          line-height: 20px;
          font-weight: bold;
        }
      }
    }
  }
  .blueContainer {
    background-color: rgb(66, 192, 243);
  }
}
</style>
